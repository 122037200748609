@gat-green: #4f706a;


#green {
	// Grey background for Green Page
	background-color: #d9d9d9;
}

@card-padding: 1.25rem; // bootstrap default

#green-login-card {
	overflow: hidden;
	border-radius: 1rem;
	box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.3);
	border: none; // Remove white hairline
	.decoration {
		background-image: url("/img/green/login-background.svg");
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: -@card-padding 0 -@card-padding -@card-padding;
		.stamp {
			width: 75%;
		}
	}
	.form {
		position: relative;
		padding: 2em 10%;
		.gl-logo {
			width: 100%;
		}
	}
}

#green-box {
	background-color: white;

	h1, h2, h3, h4, h5, h6, .btn, .pane-header {
		font-family: 'Montserrat', sans-serif;
	}

	.btn-default {
		border-color: @gat-green;
		color: @gat-green;
		background-color: white;
	}

	.btn-primary {
		border-color: @gat-green;
		color: white;
		background-color: @gat-green !important;
	}

	> .row {
		align-items: flex-start; // Override base Bootstrap behaviour so columns can have unequal heights
	}
	.detail-view {
		position: sticky;
		top: 60px;
		max-height: calc(100vh - 60px);
		overflow-y: auto;
	}

	.left-pane, .left-pane .card-header, .left-pane .card-body {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.pane-header {
		color: white;
		background-color: @gat-green;
		font-weight: bold;
		font-size: 125%;
		padding: 0.75em;
		text-transform: uppercase;
		
		.back-chevron {
			float: right;
			cursor: pointer;
		}
	}


	.GreenTagItem {
		.inner {
			background-color: white;
			box-shadow: 0 0.25rem 0.25rem rgb(0 0 0 / 40%);
			border-radius: 0.5rem;
			cursor: pointer;
			.logo {
				width: 3em;
				height: 3em;
				overflow: hidden;
			}
			span.logo {
				// no visual noise from placeholder logo
				color: transparent;
				border: 1px solid #ddd;
			}
			// Highlight currently active list item
			&.focused {
				border: 1px solid @gat-green;
				left: -0.75rem;
				cursor: default;
			}
		}
		.tag-name {
			margin: 0;
			max-width: 95%; // don't collide with status indicator
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
		.abc { // agency/brand/campaign label
			width: 40%; // make room for brand and campaign side by side
			.name {
				font-family: 'Montserrat', sans-serif;
			}
		}
	}

	/* Status and notes marker area */
	.tag-markers {
		position: absolute;
		top: 0.25rem;
		right: 0.25rem;
		display: inline-flex;
		justify-content: flex-end;
	}
	// Wrap all markers in a rounded box
	.tag-marker {
		// HACK for dev team: Tajawal vertical metrics are broken in Linux specifically & these icons depend on pixel-level positioning to look right
		font-family: 'Montserrat', sans-serif;
		border: 1px solid #ccc;
		border-radius: 0.25em;
		background-color: white;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		min-width: 2rem;
		height: 2rem;
		cursor: default;
		&:not(:first-child) {
			margin-left: 0.2rem;
		}
	}
	// Speech bubble effect on the "note count" marker
	.tag-notes-marker {
		position: relative;
		height: 1.5rem;
		padding: 0 0.25rem; // don't get crowded when expanding if there's 100+ notes
		// Use the border trick to make a triangle
		&:before, &:after {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			left: 0.5rem;
			bottom: -0.5rem;
		}
		&:before {
			border: 0.25rem solid;
			border-color: #ccc transparent transparent #ccc;
		}
		&:after {
			border: calc(0.25rem - 1px) solid;
			border-color: #fff transparent transparent #fff;
			transform: translate(1px, -2px);
		}
	}
	/* End status and notes marker area */

	// agency/brand/campaign label
	.abc {
		overflow: hidden;
		.legend-buttons .btn {
			padding: 0.25rem; // Copy and share buttons compact & inline
		}
		.name {
			width: 100%;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	.left-pane {
		.tag-name {
			width: 90%; // make room for status indicator & trigger ellipsis truncation
		}
		.abc {
			width: 30%; // 3 on one line
		}
	}

	.edit-pane {
		// Border around radio buttons
		form {
			border: 1px solid #ced4da;
			border-radius: 0.25rem;
			padding: 0.5em;
		}
	}
}


/** The modal tag-export box */
#green-tag-exporter {
	// Tag export container
	.export-box {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	.tag-export {
		font-family: monospace;
		line-break: anywhere; // best for putting a URL into
		border: 1px solid #ced4da;
		font-size: 80%;
		min-height: 100%; // match height if both copy & share buttons are present
	}
	.export-buttons {
		display: flex;
		flex-direction: column;
		justify-content: center;
		.btn {
			white-space: nowrap; // keep labels on one line - plenty of H space if the element takes it
		}
	}
}


#green-bulk-uploader {
	.preview-table {
		// Don't stretch out table layout
		table-layout: fixed;
		// Expect long names, creative URLs etc but keep everything on one line
		td, th {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		// Small column for the "uploaded" check box
		th:first-child {
			width: 3em;
		}
	}

	// Don't show "Not Published Yet" marker on template fields
	.data-modified {
		display: none;
	}

	// CSV upload field doesn't save the file, so hide its URL box
	input[type="url"] {
		display: none;
	}
	.DropZone {
		width: 100%;
	}
}

.note-modal {
	.x-close {
		&:hover {
			cursor: pointer;
		}
	}
}
