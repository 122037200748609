
.blog-editor {
    .preview-frame {
        min-height: 70vh;
    }
    textarea[name="content"] {
        min-height: 70vh;
    }
    .overlay {
        display: none;
        &.active {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width:100%;
            height:100%;
            background: rgba(127,127,127,0.5);
        }
    }
}