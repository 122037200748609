// Styling for the WYSIWYG editor: darken the whole adunit, except for the editable blocks
.wysiwyg-container {
	// Darken everything behind
	background-color: rgba(0, 0, 0, 0.4);
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	// Hard-light blend mode: overlaid blocks of neutral grey (#777, 127/127/127)
	// will restore the normal colour/brightness of the elements behind this
	mix-blend-mode: hard-light;

	.wysiwyg-zone {
		background-color: #777; // interacts with hard-light blend mode above
		border: 1px solid #ccc;
	}
}