@import '../js/base/style/colours.less';

@large-text: 3em;

//Set up CSS-Grid to use with experimental combination of pubdash and setting page
// .PubDashPage {
// 	display: inline-grid;
// 	flex-wrap: wrap;
// 	grid-template-columns: 3fr 7fr;
// 	grid-template-areas: "settings stats";
// 	grid-template-rows: auto;
// }
// .pub-settings {
// 	grid-area: "settings";
// }

//
.max-col-width td, .max-col-width th {
	max-width: 15em;
	overflow: hidden;
}
.timeline-widget {
	display: block;
	text-align: center;
	padding-top: 25px;
	padding-bottom: 10px;
	margin: auto;
}
.timeline-widget input[type=radio]{
	display:none;
}
.timeline-widget input[type="radio"]+label{
	background-color: @gl-red;
	border-color: darken(@gl-red, 10%);
	border-radius: 0;
	display: inline-block;
	font-weight: normal;
	margin: 0;
}
.timeline-widget input[type="radio"]:hover+label{
	background-color: darken(@gl-red, 10%);
	border-color: darken(@gl-red, 10%);
	cursor: pointer;
}
.timeline-widget input[type="radio"]:checked+label{
	background-color: darken(@gl-red, 10%);
}

.large-text{
	font-size: @large-text;
}

//Info icon above percentage circles
.info-icon{
	position: absolute;
	height: 25px;
	width: 25px;
	top: 20px;
}

//Percentage circles
.circular-container{
	position: relative;
	height: 240px;
	width: 240px;
	margin: auto;
	padding-top: 75px;
}
svg.radial-progress-bar{
	position: absolute;
	top: 0;
	left: 10px;
	display: inline;
	z-index: -1;
}

@media only screen and (max-width: 515px) {
	.timeline-widget{
		width: 200px;
	}
	.timeline-widget input[type="radio"]+label{
		width: 100%;
	}
}