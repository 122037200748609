@card-padding: 0.6rem;
@header-padding: 0.3rem;

.notes-list {
	.note {
		font-size: smaller;
		cursor: pointer;

		.card-header {
			padding: @header-padding;
		}
		.card-body {
			padding: @card-padding;
		}

		.x-button {
			float: right;
			font-weight: bold;
		}

		.note-author {
			font-weight: bold;
		}

		.note-text {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}