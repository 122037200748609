
.impact-settings-controls {

    .ads {
        .faded {
            opacity: 0.5;
            a.name {
                text-decoration: line-through;
            }
        }
        .hidden {
            border-color: @gl-light-red;
        }
    }

}