.dropdown-menu {
	left: unset;
	right: 0;

	.nav-link {
		color: #0056b3 !important;
	}
	.nav-link:hover {
		text-decoration: underline;
	}
}
