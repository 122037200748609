/* Good-Loop styling (which might mess up e.g. SoGive) 
NOT included by default (yet) */

// Headers

h1, .h1 {
	color: @gl-red;
	text-align: center;
	width:100%;
}

// Using flex to allow the sidelines to fill remaining space but not overflow
h1:not(.subtitle), .h1:not(.subtitle) {
	display: flex;
	display: -webkit-flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

// side lines for h1
.sideline() {
	background: #333;
	content: "";
	display: block;
	height: 1px;
	margin: 0 1em; // horizontal separation from text & edges
	flex-grow: 1;
	min-width: 5%; // make room to always show
}
h1:not(.subtitle) {
	&:before, &:after {
		.sideline();
	}
}

h1.subtitle {
	font-family: Helvetica, sans-serif !important;
	text-transform: uppercase;
	font-size: 20px; // from 36
	margin-top: -5px; // from +20
	font-weight: 300 !important;
}
// end Headers 
