@import "../js/base/style/colours.less";

.page-displayad {
    #tadg-display-page {
        width: 100%;

        .preview-col, .content-col, .display-navbar {
            position: fixed;
            height: calc(100% - 60px);
        }

        .display-navbar {
            width: 10% !important;
        }

        .content-col {
            width: 40%;
            left: 10%;
            overflow-y: auto;
            -webkit-scrollbar-track
            {
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                background-color: #F5F5F5;
            }

            -webkit-scrollbar
            {
                width: 6px;
                background-color: #F5F5F5;
            }

            -webkit-scrollbar-thumb
            {
                background-color: rgb(190,5,5);
            }
        }

        .preview-col {
            width: 50%;
            right: 0;
        }

        .display-navbar {
            min-width: 10%;
            max-width: 10%;
            flex-grow: 0;
            background-color: @gl-red;
            box-shadow: 10px 0 9px -7px rgba(0,0,0,0.4); // inset shadow on left
            padding: 0;
            min-height: 90vh;  
            color: white;
            text-transform: uppercase;
            font-family: "Montserrat";          
            :focus, :focus-visible {
                outline: none !important;
            }

            .gl-logo{
                width: 70%;
            }
            display: flex;
            flex-direction: column;
            
            .sticky-nav {
                position: sticky;
                top: 60px;
            }

            .display-nav-btn {
                background: none;
                border: none;
                padding: 5% 0 5% 5%;
                text-align: left;
                font-weight: 600;
                font-size: 18px;
                width: 100%;
                border-top: solid 1px rgba(0,0,0,0.2);
                    outline: none;
                
                
                &.active {
                    background-color: @gl-impact-red;
                }
            }
        }


        .unfinished-warning {
            background-color: rgba(255,255,255, 0.25);
            padding: 2.5% !important;
            padding-left: 5% !important;
            text-transform: none;
            font-size: small;
            text-align: left;
            margin-bottom: 0;
            width: 100%;
            border: none;
            color: white;

            &.gl-only {
                background-color: rgba(30,30,40, 0.8);
            }
            &.first {
                box-shadow: 0 20px 10px -20px rgba(0,0,0,0.65) inset;
            }
            &.active {
                background-color: rgba(255,255,255, 0.35);
            }
        }

        .previewInfo {
            text-align: center;
            .row {
                gap: 5%;
                place-content: center;
            }
        }


        .template-warning {
            position: fixed;
            width: 100%;
            height: 5%;
            bottom: 0;
            text-align: center;
            z-index: 100;
        }
        .display-content {
            box-shadow: 7px 0 9px -7px rgba(0,0,0,0.4);
        }

        .preview-col {
            min-width: 50%;
            max-width: 50%;
            background-color: @gl-light-grey;
            box-shadow: inset 7px 0 9px -7px rgba(0,0,0,0.4); // inset shadow on left
            .preview-frame {
                &[title~="billboard-preview"] {
                    transform: scale(calc(50vw / (2.5 * 970)));
                }
            }
        }

        .html-export {
            border: 1px solid @gl-light-grey;
            background-color: white;
            color: black;
            padding: 1rem;
        }
        .export-link {
            max-width: 20rem;
            margin: 0 0.5rem;
        }

        .advanced-layout {
            display: flex;
            flex-direction: row;
            justify-content: center;
            &.leaderboard,&.billboard {
                flex-direction: column-reverse;
            }
            .preview-section {
                display: flex;
                flex-direction: row;
                justify-content: center;
            }
        }

        .content-col {
            padding: 2%;
            min-width: 40%;
            max-width: 40%;
        }
        
        .events {
            &>.form-group {
                margin: 0 1rem;
            }
        }

        .sticky-preview {
            &.mpu {
                margin-top: 20%;
            }
            &.leaderboard {
                margin-top: 25%;
            }
            &.billboard{
                margin-top: 15%;
            }
            &.responsive {
                margin-top: 5%;
            }
        }

        .cause-text-editor {
            font-weight: 600;
            text-align: center;
            .form-inline {
                display: inline-flex;
            }
        }

        .cause-editor {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
            position: relative;
        }

        .editor-disabler {
            display: none;
            &.disable {
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.3);
            }
        }
    }


    #template-picker-page {

        // if user picked to make a template, keep the same @gl-red colour scheme going
        &.template {
            background-color: @gl-red;
            color: white !important;
        }

        flex-direction: column;
        padding: 2.5% 15% 0 15%;
        h1 {
            font-size: xxx-large;
        }

        .size-buttons {
            .size-button {
                font-size: x-large;
                margin: 2.5% 1%;
            }
            .selected {
                background-color: @gl-red;
                filter: brightness(1.5);
            }
        }
        .size-description {
            padding: 0 15%;
        }

        // trying to get drastically different sized images to show up at the same point has proven fiddly, unreliable and a pain.
        // Taking the easier way out and manually transforming them to be at the right spot. It's not nice but it works. 
        .size-preview {
            align-self: center;
            transform: scale(0.8);
            box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
            &#mpu {
                width: 300px;
                height: 600px;
            }
            &#mpu, &#mpu2 {
                width: 300px;
                height: 600px;
                transform: translate(0, -20%) scale(0.6);
            }
            &#billboard {
                width: 978px;
                height: 300px;
            }
            &#responsive {
                width: 400px;
                height: 400px;
            }
        }

        #template-preview { 
            box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
            place-self: center;
            transform: scale(0.8);
        }
    }

    // page for "user is deciding if they want a template or an advert"
    #template-decision-page {
        #left-template-decision, #right-template-decision {
            padding: 2.5% 10%;
            display: flex;
            flex-direction: column;

            .template-img {
                width: 40%;
                place-self: center;
                box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
            }

            &.selected {
                filter: brightness(1.1);
            }

            &.not-selected {
                filter: brightness(0.9)
            }
        }

        #right-template-decision { 
            background: rgb(245,245,245);
        }

        #left-template-decision {
            background-color: @gl-red;
            color: white;
        }
    }

    // for both intro pages, some utility classes
    #template-picker-page, #template-decision-page {
        h1.white {
            color: white !important;
            &::before, &::after { 
                background: white !important;
            }
        }
    
        // to ensure we can actually use the whole space, this class fills up all remaining space beneath the navbar with an absolute div
        position: absolute;
        height: calc(100% - 60px);
        max-height: calc(100% - 60px);
        display: flex;
        overflow: hidden;
        text-align: center;

        // enter button, same position on both intro pages
        .enter-decision {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 5%;
            width: 20%;
            margin-left: auto;
            margin-right: auto;
            font-size: xx-large;
        }

        .select-template-btn {
            margin: auto;
        }

        p {
            font-size: large;
        }
    }
    
    .footer {
        // this feels dumb but it works. As page is now made of 3 fixed blocks out of flow, footer misbehaves. Changed so it's now fixed only on right side beneath preview ad
        display: none;
    }


    .missing-required {
        .items-dropdown {
            input, button, textarea {
                border: unset;
                box-shadow: unset;
            }
        }
        input, button, textarea {
            border: solid 1px rgba(255,5,5,0.5);
            box-shadow: 0px 0px 12px 2px rgba(255,5,5,0.5);
        }
    }

    /* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: @gl-impact-red;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: @gl-red;
  }
}

.preview-container {
    display: flex;
    justify-content: center;
    .preview-controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .refresh {
            img {
                width: 1rem;
                height: 1rem;
            }
        }
    }
    .displayad-preview {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        position: relative;
        .clickable,.loading {
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .loading {
            h3 {
                display: inline-block;
                padding: 1rem;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: white;
            }
        }
    }
}

.format-modal {
    width: 75%;
    max-width: unset;

    .horizontal-previews {
        .preview-container {
            margin-bottom: 6rem;
        }
    }

    .preview-container {
        &.selected {
            .displayad-preview {
                border: 7px solid #770F00;
            }
        }
        padding: 1rem;
        border: 1px solid @gl-light-grey;
        border-radius: 10px;
        .displayad-preview {
            transform: scale(0.9);
            border: 7px solid rgba(0,0,0,0);
            border-radius: 5px;
            transition: border 0.2s;
            .preview-frame {
                border: none;

            }
            &.responsive {
                .preview-frame {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.template-card {
    padding: 2%;
    .template-container {
        background: #efefef;
        padding: 2%;

        .template-button {
            background-size: cover !important;
            color: white;
    
            .template-content {
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,0.3);
                &:hover {
                    color: white;
                    background: rgba(0,0,0,0.25);
                }
                &.selected {
                    background-color: rgba(0,0,0,0.1);
                }
            }
        }
        .selected {
            border: solid 2px @gl-red;
        }
    }
}
