/* PrismJS 1.29.0
https://prismjs.com/download.html#themes=prism-tomorrow&languages=markup+css+clike+javascript */
/**
 * prism.js tomorrow night eighties for JavaScript, CoffeeScript, CSS and HTML
 * Based on https://github.com/chriskempson/tomorrow-theme
 * @author Rose Pritchard
 */

code[class*="language-"], pre[class*="language-"] {
	color: #ccc;
	background: none;
	font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
	font-size: 1em;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;
	line-height: 1.5;

	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;

	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}

/* Code blocks */
pre[class*="language-"] {
	margin: .5em 0;
	overflow: auto;
}

:not(pre) > code[class*="language-"], pre[class*="language-"] {
	background: #2d2d2d;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
	border-radius: .3em;
	white-space: normal;
}

.token {
	&.comment, &.block-comment, &.prolog, &.doctype, &.cdata { color: #999; }
	&.punctuation { color: #ccc; }
	&.tag, &.attr-name, &.namespace, &.deleted { color: #e2777a; }
	&.function-name { color: #6196cc; }
	&.boolean, &.number, &.function { color: #f08d49; }
	&.property, &.class-name, &.constant, &.symbol { color: #f8c555; }
	&.selector, &.important, &.atrule, &.keyword, &.builtin { color: #cc99cd; }
	&.string, &.char, &.attr-value, &.regex, &.variable { color: #7ec699; }
	&.operator, &.entity, &.url { color: #67cdcc; }
	&.important, &.bold { font-weight: bold; }
	&.italic { font-style: italic; }
	&.entity { cursor: help; }
	&.inserted { color: green; }
}
