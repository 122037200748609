
// this is to make adsignup page look a bit better (fitting in nicely on one page)

// put in backdrop
#mainDiv {
	background-image: "https://i.ibb.co/92Jt7FL/test7.png";
	background-size: cover;
}

// limit textarea to appear short by default (set to be the same as standard height of input elements)
textarea.form-control {
	height: 35px;
}

// limiting height of upload box
#adsignup .DropZone {
	height: 80px;
}

//. end of adsignup prettifying
