// Styling for the Good-Loop Portal app
@import "../js/base/style/main-base.less";

// Page specific stuff
@import "Dashboard.less";
@import "AdvertPage.less";
@import "CharityControls.less";
@import "BlogEditor.less";
@import "NGOEditor.less";
@import "ImpactSettings.less";
@import "DisplayAdvertPage.less";

// CSS Support new AccountMenu
@import "navbar.less";

@import "../js/base/style/good-loop.less";

// adsignup re-design @IP
@import 'AdSignUp.less';

// specific components
@import "LivePreviewableEditor.less";
@import "Wysiwyg.less";

// // lightly shade the BG so that forms pop out a bit - but it looks bad
// #mainDiv {
// 	background-color: #ddd;
// }

// Buttons

// Make btn-primary gl-blue ??no, that's a bit matt for a CTA
// @btnprimarycol: @blue;
// #gl-body .btn-primary {
// 	background: @btnprimarycol;
// 	border-color: @btnprimarycol;
// 	&:hover {
// 		background: darken(@btnprimarycol, 7.5%);
// 		border-color: darken(@btnprimarycol, 10%); 
// 	}
// 	&:active, &:focus {
// 		background: darken(@btnprimarycol, 10%);
// 		border-color: darken(@btnprimarycol, 12.5%);
// 	}
// }

// Increase the footer height so there's always space for the save-publish-discard widget when present
#gl-body footer {
	padding-bottom: 7.5em;
}