.page#measure {
	
	.overview {// The at-a-glance page info
		.manifest-screenshot { // Limit screenshot size
			max-width: 50%;
			max-height: 15rem;
		}
	}
	// Type breakdown
	.type-breakdown {
		display: flex; // allow bar and table side by side
		// Vertical bar
		.breakdown-bar {
			position: relative; // size ref for segments
			display: flex;
			flex-direction: column;
			width: 3rem;
			height: 100%;
			border: 1px solid black;

			.bar-segment {
				width: 100%;
				display: block;
				margin: 0;
				min-height: 2px;
				&:not(:last-child) {
					margin-bottom: 1px;
				}
			}
		}
		// Table beside bar
		.breakdown-table {
			width: calc(100% - 3.5rem); // 3em occupied by bar to left + margin
			margin-left: 0.5rem;
			.breakdown-key {
				position: relative;
				display: block;
				width: 2em;
				height: 1.5em;
			}
		}
	}

	.sub-frames {
		.sub-frame {
			.card-img {
				max-height: 7.5rem;
				object-fit: contain;
			}
		}
	}

	// Don't try to do word breaks in URLs
	a.item-url {
		line-break: anywhere;
	}

	.opt-rec {
		.preview {
			max-height: 5rem;
			object-fit: contain;
			border: 1px solid rgba(0, 0, 0, 0.125);
		}
		.font-preview {
			font-size: 2rem;
			line-height: 1;
			text-align: center;
			color: white;
			background-color: navy;
		}
	}
}