.charity-logo-sample {
	background-color: white;
	border-radius: 50%;
	border: 1px solid grey;
	height: 100px;
	width: 100px;
	position: relative;
	background-size: auto; 
	background-position: center;
	background-repeat: no-repeat;
	margin: auto;

	.name {
		position: absolute;
		background-color: white;
		border: 1px solid gray;
		text-align: center;
		top: 60%;
		width: 100%;
	}
}