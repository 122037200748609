#advert.page {
	// where the logo, ID, advertiser and edit status appear in a compact block at the top
	.basic-info {
		display: flex;
		flex-direction: row;
		align-items: center;
		> :not(:first-child) {
			margin-left: 1em;
		}
	}

	// The following applies to the Preview-Card.
	// If user has selected portrait preview, apply these hardcoded css rules
	// to maintain aspect ratio.
	.preview-card {
		&.portrait-preview {
			.goodLoopContainer {
				height: 500px;
				margin: 0 auto;
			}
		}
	}

	// ListLoad offers an additional form, and a button for each item on the AdvertPage. Rules below make them more compact and better spaced.
	.sort-archive-form {
		display: flex;
		flex-direction: row;
		
		.form-group.select {
			max-width: 45%;
		}
		.form-check.form-check-inline {
			margin: 0 2em;
		}
	}

	.ListItemWrapper button {
		margin: 0 1em;
	}
	
	.ListItem {
		position: relative; // use as position ref for serving badge
		.serving {
			position: absolute;
			top: 0.5em;
			left: 100%;
			margin-left: -0.5em;
			.serving-desc {
				display: inline-block;
				width: 0;
				opacity: 0;
			}
			&:hover .serving-desc {
				width: unset;
				opacity: 1;
			}
		}
	}
	/* The adunit layout selector should be a row of thumbnails */
	.player-variants {
		.form-check {
			display: inline-block;
			&:not(:last-child) {
				margin-right: 0.5em;
			}
		}
		
		label.form-check-label {
				width: 7.5em;
		}
	}
	
} // ./advert-page


	// clear OK/warning/error signifiers for the "what am I missing to view this ad?" checklist
.checklist {
	.unknown {
		&:before {
			// color: #009900;
			content: '-';
		}
	}
	.success {
		&:before {
			color: #009900;
			content: '\2714';
		}
	}
	.warning {
		&:before {
			content: '\26a0';
		}
		color: #bb7700;
	}
	.error {
		&:before {
			content: '\2718';
		}
		color: #990000;
	}
}