.ngo-editor {
    .preview-frame {
        min-height: 70vh;
    }
    .blue {
        color: blue !important;
    }
    .img-preview {
        max-width: 100px;
        max-height: 100px;
        &:hover {
            cursor: pointer;
        }
    }
    .expanded-item {
        margin: 0 -1.25rem;
        padding: 1rem 1.25rem;
        border-top: 1px solid @gl-light-grey;
        border-bottom: 1px solid @gl-light-grey;
        background-color: white;
    }
}

// For the "comparison with main version" feature seen when editing charities on an adunit
.prop-control-compare {
	.editing-version {
		flex-grow: 1; // take up available space
	}

    // Restrict width & neatly truncate text
    .reference-version {
        max-width: 30%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

// Editor for an NGO's set of MediaObjects - p
.ngo-image-list-editor {
    .ngo-image-item {
        .controls {
            &, .form-group {
                flex-grow: 1;
            }
        }
    }
}