
@keyframes refresh-rotate {
    from {transform: rotate(0deg)}
    to {transform: rotate(360deg)}
}

.live-previewable-editor {
    .live-refresh {
        width: 20px;
        height: 20px;
        &:hover {
            cursor: pointer;
            &.refreshing {
                cursor: wait;
            }
        }
        &.refreshing {
            animation-name: refresh-rotate;
            animation-iteration-count: infinite;
            animation-duration: 0.5s;
        }
    }
    .stick-to-screen:not(.full-width) {
        // Found from inspect element; assuming everyone is using portal on laptops/desktops, this hack is fine
        width: 555px;
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
    }
}